export const Constants = {
  SYSTEM: {
    DEFAULT_TITLE: 'sWorks',
    DEFAULT_TENANT_ID: 'sworksio001',
    DEFAULT_ICON: 'https://social-cfd-test.sworks.io/sworksio/assets/ca5a7df3640bcce84585c3d1ab2802fc.png',
    DEFAULT_BANNER: 'https://social-cfd-test.sworks.io/sworksio/assets/0541015af1ee5bd1f21a0cf973457a55.png',
    DEFAULT_THEME: {
      '--bg-primary': '#3B8396',
      '--bg-secondary': '#282828',
      '--primary': '#1DB954',
      '--text-alternate': '#666666',
      '--text-primary': '#FFFFFF',
      '--text-secondary': '#B3B3B3',
    },
    DEFAULT_LOADER: {
      TYPE: 'Bars',
      COLOR: '#00BFFF',
      BACKGROUND: '#B0D7E5',
    },
    VALUE_DEFAULT_RESPONSE_TYPE: 'json',
    COPYRIGHT_FOOTER: `Copyright © ${new Date().getFullYear()} sWorks. All rights reserved`,
    WEBSITE_LINK: 'https://www.sworks.io',
    DEFAULT_NAVIGATION_CONFIGURATIONS: [
      {
        title: 'About Us',
        redirection: '#about',
      },
      {
        title: 'Testimonials',
        redirection: '#testimonials',
      },
      {
        title: 'Book a Cab',
        redirection: '#booking',
      },
    ],
  },
  FIELD: {
    TENANT_ID: 'tenantId',
    AUTH_TOKEN: 'authToken',
    USER: 'user',
    IS_AUTHENTICATED: 'isAuthenticated',
    CONFIGURATIONS: 'configurations',
    DASHBOARD_CONFIGURATIONS: 'dashboard_configurations',
    REFETCH_ON: 'refetchOn',
    SELECTED_MENU: 'selectedMenu',
  },
};

export default Constants;
