// NPM modules
import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import '../node_modules/@sworksio/dashboard-core/lib/index.css';
import '../node_modules/@sworks-platform/gp001-payment-web/lib/index.css';
import 'react-loading-skeleton/dist/skeleton.css';
import 'react-datepicker/dist/react-datepicker.css';
import RideBookingPage from './pages/RideBooking/RideBookingPage';
import AboutUs from './custom/Slingshot/AboutUs/AboutUs';
import TrackingPage from './pages/Tracking/TrackingPage';
// Constants file
// import PathConstants from './shared/constants/PathConstants';
// Components file
import Loader from './components/Loader/Loader.lazy';
// Pages file
import Home from './pages/Home/Home.lazy';
// Support modules
// import history from './shared/providers/History';
import './App.module.scss';
import StripePayment from './components/Payment/StripePayment';
import { initGA } from '@sworksio/dashboard-core';
import CheckOut from './components/CheckOut/CheckOut';
import RideEstimatePage from './pages/RideEstimate/RideEstimatePage';

function App() {
  initGA(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID);

  return (
    <BrowserRouter>
      <React.Suspense fallback={<Loader />}>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/ride-booking" element={<RideBookingPage />}></Route>
          <Route path="/ride-booking-estimate" element={<RideEstimatePage />}></Route>
          {/* <Route path="/" render={(props) => <Home {...props} />} /> */}
          {/* <Route path={PathConstants.ERROR} /> */}
          <Route path="/payment" element={<StripePayment />}></Route>
          <Route path="/slingshot-about-us" element={<AboutUs />}></Route>
          <Route path="/tracking/:id" element={<TrackingPage />}></Route>
          <Route path="/checkout" element={<CheckOut />}></Route>
        </Routes>
      </React.Suspense>
    </BrowserRouter>
  );
}

export default App;
