import React, { lazy, Suspense } from 'react';
import Loader from '../../components/Loader/Loader.lazy';

const LazyHome = lazy(() => import('./Home'));

const Home = (props) => (
  <Suspense fallback={<Loader />}>
    <LazyHome {...props} />
  </Suspense>
);

export default Home;
