export const WebsiteConfigurations = [
  {
    domainName: 'default',
    defaultQueryParams: 'type=SERVICE_CATEGORY',
    loader: {
      type: 'Bars',
      color: '#00BFFF',
      background: '#B0D7E5',
    },
  },
];

export const OnLoadConfigurations = {
  selectableValue: [
    {
      key: 'catalogueId',
      type: 'default',
      mappedKey: '_id',
      defaultQueryParams: 'type=SERVICE_CATEGORY&isActive=true',
    },
  ],
};

export const RideBookingConfigurations = {
  title: 'Book Your Ride',
  subTitle: 'Book your Ride',
  description: 'Fill in your details and book your ride with ease',
  submit: 'NEXT',
  form: [
    {
      id: 'pickupAddress',
      label: 'Pickup Address',
      placeholder: 'Enter Pickup Address',
      type: 'location',
      validationType: 'mixed',
      validations: [
        {
          type: 'required',
          params: ['Please select a pickup address for your ride'],
        },
      ],
      notFoundError: 'No matching location found',
      apiKey: 'AIzaSyA6XrzjQb2O0ImkkAVD8w1sA-t05e8__PE',
      searchable: true,
    },
    {
      id: 'dropAddress',
      label: 'Drop Address',
      placeholder: 'Enter Drop Address',
      type: 'location',
      validationType: 'mixed',
      validations: [
        {
          type: 'required',
          params: ['Please select a drop address for your ride'],
        },
      ],
      notFoundError: 'No matching location found',
      apiKey: 'AIzaSyA6XrzjQb2O0ImkkAVD8w1sA-t05e8__PE',
      searchable: true,
    },
    {
      id: 'startTime',
      label: 'Date',
      placeholder: 'Enter Scheduled Date',
      type: 'date',
      showTimeSelect: true,
      validationType: 'date',
      value: '',
      isUtc: true,
      maxAllowedDays: 30,
      timeIntervals: 10,
      validations: [
        {
          type: 'required',
          params: ['Scheduled date is required'],
        },
      ],
    },
    {
      id: 'catalogueId',
      label: 'Service type',
      placeholder: 'Please select service type',
      type: 'select',
      validationType: 'string',
      value: '',
      options: [],
      validations: [
        {
          type: 'required',
          params: ['Please select the service type you would like to book'],
        },
      ],
    },
  ],
};
export const RideBookingTabConfigurations = [
  {
    id: 'tripDetails',
    label: 'Trip Details',
    icon: 'FaCar',
  },
  {
    id: 'accountDetails',
    label: 'Account Details',
    icon: 'FaUser',
  },
  {
    id: 'bookingSummary',
    label: 'Pay Now',
    icon: 'FaRegCreditCard',
  },
];
export const UserConfigurations = {
  title: 'Book Your Ride',
  submit: 'Book Now',
  form: [
    {
      id: 'userData-givenName',
      label: 'First Name',
      placeholder: 'Enter First Name',
      type: 'text',
      subType: 'text',
      validationType: 'string',
      value: '',
      validations: [
        {
          type: 'required',
          params: ['First name is required'],
        },
        {
          type: 'max',
          params: [20, 'First name cannot be more than 20 characters'],
        },
      ],
    },
    {
      id: 'userData-familyName',
      label: 'Last Name',
      placeholder: 'Enter Last Name',
      type: 'text',
      subType: 'text',
      validationType: 'string',
      value: '',
      validations: [
        {
          type: 'required',
          params: ['Last name is required'],
        },
        {
          type: 'max',
          params: [20, 'Last name cannot be more than 20 characters'],
        },
      ],
    },
    {
      id: 'userData-contactNumber',
      label: 'Mobile Number',
      placeholder: 'Enter Mobile Number (without country code)',
      type: 'text',
      subType: 'text',
      value: '',
      validationType: 'number',
      validations: [
        {
          type: 'required',
          params: ['Please enter your mobile number'],
        },
        {
          type: 'min',
          params: [100000000, "Mobile number can't be less than 9 digits"],
        },
        {
          type: 'max',
          params: [99999999999, "Mobile number can't be more than 11 digits"],
        },
        {
          type: 'typeError',
          params: ['Mobile number should have numeric digits'],
        },
      ],
    },
    {
      id: 'userData-email',
      label: 'Email Address',
      placeholder: 'Enter Email Address',
      type: 'text',
      subType: 'text',
      validationType: 'string',
      value: '',
      validations: [
        {
          type: 'email',
          params: ['Entered email address seems to be invalid'],
        },
        {
          type: 'required',
          params: ['Please enter an email address'],
        },
      ],
    },
  ],
};

export const OnConfirmBookingConfigurations = {
  title: 'Booking Confirmed',
  subTitle: '',
  description:
    'Your details have been received you will shortly get the information about your trip via sms and email.',
  image: 'https://social-cfd-test.sworks.io/sworksio001/assets/b37518ddec8ac29420315edacaf3535c.png',
};

export const TripDetailsConfigurations = {
  config: [
    {
      key: 'address.pickupAddress.addressLine1',
      label: 'Pickup Address',
      type: 'text',
    },
    {
      key: 'address.dropAddress.addressLine1',
      label: 'Dropoff Address',
      type: 'text',
    },
    {
      key: 'startTime',
      label: 'Pickup Date',
      type: 'date',
    },
    {
      key: 'title',
      label: 'Vehicle',
      type: 'text',
    },
  ],
};

export const ContactUsConfigurations = {
  title: 'Connect with us!',
  description: 'Have an inquiry or feedback for us? Fill out the form below to contact us.',
  submit: 'Submit',
  image: 'https://social-cfd-test.sworks.io/eagletaxi001/assets/9438958e26fc167ed1aad0c551661785.png',
  form: [
    {
      id: 'name',
      label: 'Name',
      placeholder: 'Enter Name',
      type: 'text',
      subType: 'text',
      validationType: 'string',
      value: '',
      validations: [
        {
          type: 'required',
          params: ['Name is required'],
        },
        {
          type: 'max',
          params: [20, 'Name cannot be more than 20 characters'],
        },
      ],
    },
    {
      id: 'emailAddress',
      label: 'Email Address',
      placeholder: 'Enter Email Address',
      type: 'text',
      subType: 'text',
      validationType: 'string',
      value: '',
      validations: [
        {
          type: 'email',
          params: ['Email address seems to be invalid'],
        },
        {
          type: 'required',
          params: ['Email address is required'],
        },
      ],
    },
    {
      id: 'contactNumber',
      label: 'Mobile Number',
      placeholder: 'Enter Mobile Number (without country code)',
      type: 'text',
      subType: 'text',
      value: '',
      validationType: 'number',
      validations: [
        {
          type: 'required',
          params: ['Mobile number is required'],
        },
        {
          type: 'min',
          params: [100000000, "Mobile number can't be less than 9 digits"],
        },
        {
          type: 'max',
          params: [99999999999, "Mobile number can't be more than 11 digits"],
        },
        {
          type: 'typeError',
          params: ['Mobile number should have numeric digits'],
        },
      ],
    },
    {
      id: 'content',
      label: 'Message',
      placeholder: 'Enter Message',
      type: 'textArea',
      subType: 'text',
      validationType: 'string',
      value: '',
      validations: [
        {
          type: 'required',
          params: ['Message is required'],
        },
      ],
    },
  ],
  staticData: {
    status: 'OPEN',
    type: 'CONTACT_REQUEST',
  },
};

export const PaymentConfiguration = {
  title: 'Book Your Ride',
  subTitle: 'Book your Ride',
  description: 'Book your ride with ease',
  showAmountInPayButton: true,
  showCustomLabel: false,
  methods: [
    {
      type: 'CASH',
      label: 'Cash',
      iconUrl: '',
      isEnable: true,
    },
  ],
};

export const CustomAboutUsConfiguration = {
  title: 'About Us',
  description:
    "As the leader in the industry, we always asked ourselves, would we want to exchange our hard-earned money on a fly by night operation and hope to get a decent ride. So we set out not only to dominate the market but to become the biggest and the best! At Street Dream Rentals you will find the largest selection of Slingshots in the state! You will also find the 1 and ONLY legitimate shop in Miami. No, no more pulling up to shady people personal homes. No more wondering if you're ever going to see your money or even that guy you spoke to? We have a 10,000 sq ft. lot in the heart of Wynwood and offer an amazing waiting room fully equipped with FREE drinks, flat-screen TV and surround sound, a pool table, dartboard and so much more!!!",
  image: 'https://social-cfd-test.sworks.io/slingshot001/5f69803e5f51595e85bcc632/cb0f7d06e865192e00101055ce867f09.png',
  features: [
    {
      title: 'The Biggest & The Best',
      image:
        'https://social-cfd-test.sworks.io/slingshot001/5f69803e5f51595e85bcc632/a18cba501f54b67f1bcb8893ba2fe51a.png',
    },
    {
      title: 'Luxury At Its Finest',
      image:
        'https://social-cfd-test.sworks.io/slingshot001/5f69803e5f51595e85bcc632/5a50752d4bcdc66450fd40e4e645320d.png',
    },
    {
      title: 'The Show Stopper',
      image:
        'https://social-cfd-test.sworks.io/slingshot001/5f69803e5f51595e85bcc632/7902e39a71411e55c686cee345ae9bd4.png',
    },
  ],
};
