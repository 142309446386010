import React, { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import axios from 'axios';
// import { getWebsiteConfiguration } from '../../shared/providers/Utils';
// Constants file
import Constants from '../../shared/constants/AppConstants';
import UrlConstants from '../../shared/constants/UrlConstants';
import { themeStyling } from '../../shared/providers/ThemeStyler';
// Support modules
import Loader from '../../components/Loader/Loader';
import RideBooking from '../../components/RideBooking/RideBooking.lazy.js';
import styles from './RideBookingPage.module.scss';
import { ErrorHandler } from '@sworksio/dashboard-core';
import { useGAPageViewTracker } from '@sworksio/dashboard-core';
import { Helmet } from 'react-helmet';
import { getDomain } from '../../shared/providers/Utils';

const RideBookingPage = () => {
  const [tenantId, setTenantId] = useState(null);
  const [errorCode, setErrorCode] = useState(null);
  const [domainConfiguration, setDomainConfiguration] = useState(null);
  const [websiteConfiguration, setWebsiteConfiguration] = useState(null);
  const [isConfigurationFetched, setIsConfigurationFetched] = useState(false);
  const [theme, setTheme] = useState(Constants.SYSTEM.DEFAULT_THEME);
  const [seo, setSeo] = useState(null);
  const pageView = useGAPageViewTracker();
  // const history = useNavigate();

  useEffect(() => {
    const API_HOST_URL = process.env.REACT_APP_API_HOST_URL;
    const domain = new URL(window.location);
    const hostName = getDomain(domain.hostname);
    axios
      .get(`${API_HOST_URL}${UrlConstants.GET_DOMAIN_CONFIG}?domainName=${hostName}`)
      .then((res) => {
        processWebsiteConfigurationsData(res);
      })
      .catch((err) => {
        processWebsiteConfigurationsError();
      });
    // eslint-disable-next-line
  }, []);

  function processWebsiteConfigurationsData(res) {
    if (res.data?.result) {
      const domainConfigurations = res.data?.result;
      if (!domainConfiguration || domainConfiguration === {}) {
        setErrorCode(404);
      }
      const websiteConfiguration = domainConfigurations.webConfigs;
      localStorage.setItem('timeZone', websiteConfiguration?.feature?.booking?.timezone || null);
      setDomainConfiguration(domainConfigurations);
      setTenantId(domainConfigurations.tenantId);
      setSeo(domainConfigurations?.seo);
      setWebsiteConfiguration(websiteConfiguration);
      // document.title = domainConfigurations?.name;
      // let titleElement = document.querySelector('title');
      // if (titleElement) titleElement.innerHTML = domainConfigurations.name || Constants.SYSTEM.DEFAULT_TITLE;
      pageView(window.location.pathname + window.location.search);
      if (!websiteConfiguration?.theme) {
        setErrorCode(400);
      }
      themeStyling(websiteConfiguration?.theme?.primaryColor, websiteConfiguration?.theme?.name);
      if (!theme) {
        setTheme(websiteConfiguration?.theme);
      }
      localStorage.setItem(Constants.FIELD.CONFIGURATIONS, JSON.stringify(domainConfiguration));
      setIsConfigurationFetched(true);
    } else {
      processWebsiteConfigurationsError();
    }
  }

  function processWebsiteConfigurationsError() {
    setErrorCode(400);
  }

  return (
    <div className={styles.RideBookingPage} data-testid="RideBookingPage">
      {tenantId && isConfigurationFetched ? (
        <>
          {domainConfiguration ? (
            <div>
              <Helmet>
                <title>{seo?.title}</title>
                <meta charset="utf-8" />
                {/* <link rel="icon" href="%PUBLIC_URL%/favicon.ico" /> */}
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                {/* <meta name="theme-color" content="#004BAD" /> */}
                <meta name="description" content={seo?.discription} />
                <meta property="keywords" content={seo?.keywords.toString()} />
                <meta property="og:image" content={seo?.ogImageUrl} />
                <meta property="og:image:width" content={seo?.ogImageWidth} />
                <meta property="og:image:height" content={seo?.ogImageHeight} />
                <meta property="og:image:type" content="image/png" />
              </Helmet>
              {websiteConfiguration.feature?.booking ? (
                <RideBooking
                  data={websiteConfiguration.feature?.booking}
                  customTheme={websiteConfiguration.theme}
                  tenantId={tenantId}
                  urls={domainConfiguration.url}
                  domainConfiguration={domainConfiguration}
                />
              ) : null}
            </div>
          ) : (
            <Loader />
          )}
        </>
      ) : (
        <>
          {errorCode ? (
            <ErrorHandler
              errorCode={errorCode}
              errorMsg={`Configurations not found for your request`}
              showTryAgainButton={true}
              occupyFullScreen={true}
            />
          ) : (
            <Loader />
          )}{' '}
        </>
      )}
    </div>
  );
};

export default RideBookingPage;
