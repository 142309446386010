import { themeOptions } from '../../styles/ThemeOptions';

export function themeStyling(primaryColor, themeName) {
  const selectedTheme = themeOptions.find((theme) => theme.name.toLowerCase() === themeName.toLowerCase()) || {};
  const html = document.getElementsByTagName('html')[0];
  Object.keys(selectedTheme).forEach((property) => {
    html.style.setProperty(property, selectedTheme[property]);
  });
  html.style.setProperty('--bg-primary', primaryColor);
}
