// NPM modules
import React, { useState, useEffect } from 'react';
import axios from 'axios';
// Constants file
import Constants from '../../../shared/constants/AppConstants';
import styles from './AboutUs.module.scss';
import headerStyles from '../../../components/Header/Header.module.scss';
import footerStyles from '../../../components/Footer/Footer.module.scss';
import { CustomAboutUsConfiguration } from '../../../shared/constants/ConfigConstants';
import { themeStyling } from '../../../shared/providers/ThemeStyler';
import UrlConstants from '../../../shared/constants/UrlConstants';
import Loader from '../../../components/Loader/Loader';
import { ErrorHandler, Icon } from '@sworksio/dashboard-core';
import { useNavigate } from 'react-router-dom';

const AboutUs = ({ name, data, ...props }) => {
  const [domainConfiguration, setDomainConfiguration] = useState(null);
  const [isConfigurationFetched, setIsConfigurationFetched] = useState(false);
  const [errorCode, setErrorCode] = useState(null);
  const [menuBar, setMenuBar] = useState(false);
  const item = CustomAboutUsConfiguration;
  const history = useNavigate();

  useEffect(() => {
    let domainConfigurations = JSON.parse(localStorage.getItem('domainConfiguration'));
    if (domainConfigurations) {
      // document.title = domainConfigurations?.name;
      setDomainConfiguration(domainConfigurations);
      themeStyling(
        domainConfigurations?.webConfigs?.theme?.primaryColor,
        domainConfigurations?.webConfigs?.theme?.name,
      );
      setIsConfigurationFetched(true);
    } else {
      const API_HOST_URL = process.env.REACT_APP_API_HOST_URL;
      const domain = new URL(window.location);
      const hostName = domain.hostname;
      axios
        .get(`${API_HOST_URL}${UrlConstants.GET_DOMAIN_CONFIG}?domainName=${hostName}`)
        .then((res) => {
          processWebsiteConfigurationsData(res);
        })
        .catch((err) => {
          processWebsiteConfigurationsError();
        });
    }
    // eslint-disable-next-line
  }, []);

  function processWebsiteConfigurationsData(res) {
    if (res.data?.result) {
      const domainConfigurations = res.data?.result;
      if (!domainConfiguration || domainConfiguration === {}) {
        setErrorCode(404);
      }
      const websiteConfiguration = domainConfigurations.webConfigs;
      setDomainConfiguration(domainConfigurations);
      if (!websiteConfiguration?.theme) {
        setErrorCode(400);
      }
      // document.title = domainConfigurations?.name;
      themeStyling(websiteConfiguration?.theme?.primaryColor, websiteConfiguration?.theme?.name);
      localStorage.setItem(Constants.FIELD.CONFIGURATIONS, JSON.stringify(domainConfiguration));
      setIsConfigurationFetched(true);
    } else {
      processWebsiteConfigurationsError();
    }
  }

  function processWebsiteConfigurationsError() {
    setErrorCode(400);
  }

  function onMenuClick() {
    const menu = document.getElementsByClassName('header-nav')[0];
    if (!menuBar) {
      menu.style.display = 'block';
    } else {
      menu.style.display = 'none';
    }
    setMenuBar(!menuBar);
  }

  function hideNavBar() {
    const menu = document.getElementsByClassName('header-nav')[0];
    if (menuBar) {
      if (window.innerWidth < 1024) {
        menu.style.display = 'none';
      }
    }
    setMenuBar(!menuBar);
  }

  return (
    <>
      {isConfigurationFetched ? (
        <>
          {domainConfiguration ? (
            <div>
              <section id="home">
                <header>
                  <div className={headerStyles.container}>
                    <div
                      className={headerStyles.logo}
                      onClick={() => {
                        history('/');
                      }}
                    >
                      <div>
                        <img src={domainConfiguration.logo} alt={domainConfiguration.name} />
                      </div>
                    </div>
                    <div className={headerStyles.navigation}>
                      <button className={headerStyles.navButton} onClick={() => onMenuClick()}>
                        <span></span>
                      </button>
                      <ul className="header-nav">
                        <li id="home" onClick={() => hideNavBar()}>
                          <a aria-current="page" href="/">
                            Home
                          </a>
                        </li>
                        <li id="home" onClick={() => hideNavBar()}>
                          <a aria-current="page" href="">
                            About Us
                          </a>
                        </li>
                        <li id="booking" onClick={() => hideNavBar()}>
                          <a aria-current="page" href="/#booking">
                            Book Slingshot
                          </a>
                        </li>
                        <li id="contact-us" onClick={() => hideNavBar()}>
                          <a aria-current="page" href="/#contact-us">
                            Contact Us
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </header>
              </section>
              <section className={styles.customAboutUsGrid}>
                <div className={styles.container}>
                  <div className={styles.customAboutUsRow}>
                    <div className={styles.customAboutUsText}>
                      <h3>{item.title}</h3>
                      <p>{item.description}</p>
                    </div>
                    <div className={styles.customAboutUsImage}>
                      <img src={item.image} alt="" />
                    </div>
                  </div>
                </div>
              </section>
              <section className={styles.offerFeaturs}>
                <h2>Just a taste of What we Offer!!!</h2>
                <div className={`${styles.offerFeatuesContainer}`}>
                  <div className="row">
                    {item.features.map((offerItem) => {
                      return (
                        <div className="col-md-4 col-sm-12 colCards">
                          <div className={`${styles.offerFeaturesCard} card`}>
                            <img src={offerItem.image} alt="" />
                            <div className="card-body">
                              <div className="card-head">
                                <h3>{offerItem.title}</h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </section>
              <footer>
                <div className={footerStyles.container}>
                  <h2 className={footerStyles.logo}>{domainConfiguration.name}</h2>
                  <div className={footerStyles.top}>
                    <h3>Discover</h3>
                    <ul className={footerStyles.footerNav}>
                      <li id="home">
                        <a aria-current="page" href="/">
                          Home
                        </a>
                      </li>
                      <li id="aboutUs">
                        <a aria-current="page" href="">
                          About Us
                        </a>
                      </li>
                      <li id="contact-us">
                        <a aria-current="page" href="/#contact-us">
                          Contact Us
                        </a>
                      </li>
                      <li id="privacyPolicy">
                        <a aria-current="page" href={domainConfiguration.url.privacyPolicy}>
                          Privacy Policy
                        </a>
                      </li>
                      <li id="termsAndConditions">
                        <a aria-current="page" href={domainConfiguration.url.termsAndConditions}>
                          Terms and Conditions
                        </a>
                      </li>
                    </ul>
                  </div>
                  {domainConfiguration.url && domainConfiguration.url.facebook ? (
                    <div className={footerStyles.bottom}>
                      <h3>Special Links</h3>
                      <ul className={footerStyles.socialNav}>
                        {domainConfiguration.url.instagram ? (
                          <a href={domainConfiguration.url.instagram}>
                            <Icon iconName={`FaInstagram`} />
                          </a>
                        ) : (
                          ''
                        )}
                        {domainConfiguration.url.facebook ? (
                          <a href={domainConfiguration.url.facebook}>
                            <Icon iconName={`FaFacebookF`} />
                          </a>
                        ) : (
                          ''
                        )}
                        {domainConfiguration.url.twitter ? (
                          <a href={domainConfiguration.url.twitter}>
                            <Icon iconName={`FaTwitter`} />
                          </a>
                        ) : (
                          ''
                        )}
                        {domainConfiguration.url.youtube ? (
                          <a href={domainConfiguration.url.youtube}>
                            <Icon iconName={`FaYoutube`} />
                          </a>
                        ) : (
                          ''
                        )}
                        {domainConfiguration.url.gplus ? (
                          <a href={domainConfiguration.url.gplus}>
                            <Icon iconName={`FaGooglePlusG`} />
                          </a>
                        ) : (
                          ''
                        )}
                      </ul>
                    </div>
                  ) : (
                    ''
                  )}
                  <div className={`${footerStyles.textCenter} ${footerStyles.branding}`}>
                    {domainConfiguration?.showSworksBranding === false ? (
                      <></>
                    ) : (
                      <a href="https://www.sworks.io" className={footerStyles.linkColor}>
                        Powered by <b>sWorks.io</b>
                      </a>
                    )}
                    <br />
                    Copyright <span style={{ fontSize: '18px' }}>&copy;</span> {`${new Date().getFullYear()} ${name}`},
                    All Rights Reserved
                  </div>
                </div>
              </footer>
            </div>
          ) : (
            <Loader />
          )}
        </>
      ) : (
        <>
          {errorCode ? (
            <ErrorHandler
              errorCode={errorCode}
              errorMsg={`Configurations not found for your request`}
              showTryAgainButton={true}
              occupyFullScreen={true}
            />
          ) : (
            <Loader />
          )}{' '}
        </>
      )}
    </>
  );
};

AboutUs.propTypes = {};

AboutUs.defaultProps = {};

export default AboutUs;
