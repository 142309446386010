import axios from 'axios';
import { getDomain } from '../Utils';
import UrlConstants from '../../constants/UrlConstants';
export const getDomainConfigurations = async () => {
  localStorage.removeItem('domainConfiguration');
  const API_HOST_URL = process.env.REACT_APP_API_HOST_URL;
  const domain = new URL(window.location);
  const hostName = getDomain(domain.hostname);
  const domainConfigurations = await axios.get(
    `${API_HOST_URL}${UrlConstants.GET_DOMAIN_CONFIG}?domainName=${hostName}`,
  );
  localStorage.setItem('domainConfiguration', JSON.stringify(domainConfigurations.data.result));
  return domainConfigurations.data?.result;
};
