// NPM modules
import React from 'react';
// Constants file
// import Constants from '../../shared/constants/AppConstants';
import styles from './DownloadLinks.module.scss';
import appStoreLogo from '../../shared/assets/images/app_store.png';
import playStoreLogo from '../../shared/assets/images/play_store.png';

const DownloadLinks = ({ urls, showBookNow, ...props }) => {
  return (
    <section className={styles.downloadLinks} id="downloadLinks">
      {urls ? (
        <div className={styles.link}>
          {urls.appStore ? (
            <div className={styles.block}>
              <a href={urls.appStore}>
                <img src={appStoreLogo} alt="App Store Link" />
              </a>
            </div>
          ) : (
            ''
          )}
          {urls.playStore ? (
            <div className={styles.block}>
              <a href={urls.playStore}>
                <img src={playStoreLogo} alt="Play Store Link" />
              </a>
            </div>
          ) : (
            ''
          )}
          {showBookNow ? (
            <div className={styles.block}>
              <div className={styles.bookNow}>
                {' '}
                <a href="#booking">Book Now</a>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      ) : (
        ''
      )}
    </section>
  );
};

DownloadLinks.propTypes = {};

DownloadLinks.defaultProps = {};

export default DownloadLinks;
