import React, { useEffect, useState } from 'react';
import { Payment } from '@sworks-platform/gp001-payment-web';
import Header from '../Header/Header.lazy.js';
import Footer from '../Footer/Footer.lazy.js';
import styles from './StripePayment.module.scss';
import BookingConfirm from '../BookingConfirm/BookingConfirm';
import axios from 'axios';
import Swal from 'sweetalert2';
import { themeStyling } from '../../shared/providers/ThemeStyler';
import Loader from '../../components/Loader/Loader';
import { iframeValidation } from '../../shared/providers/Utils.js';
import { getTimeZone } from '../../shared/providers/Utils';

const StripePayment = ({ ...props }) => {
  const [cabBooked, setCabBooked] = useState(false);
  const [confirmPage, setConfirmPage] = useState(false);
  const [onLoadData, setOnLoadData] = useState(false);
  let domainConfiguration = JSON.parse(localStorage.getItem('domainConfiguration'));
  let details = {};
  if (domainConfiguration) {
    details = {
      hostUrl: process.env.REACT_APP_API_HOST_URL,
      userId: domainConfiguration.userId,
      userToken: domainConfiguration.token,
      stripeKey: domainConfiguration.stripeKey,
      tenantId: domainConfiguration.tenantId,
      redirectionUrl: domainConfiguration.paymentRedirectionUrl,
    };
    themeStyling(domainConfiguration?.webConfigs?.theme?.primaryColor, domainConfiguration?.webConfigs?.theme?.name);
  }

  useEffect(() => {
    if (domainConfiguration) {
      setOnLoadData(true);
    }
  }, []);

  async function onSuccess(data) {
    if (data?.paymentMethod) {
      if (localStorage.getItem('orderId')) {
        const orderId = localStorage.getItem('orderId');
        const confirmData = await confirmCardOrder(orderId, data?.paymentMethod, data?.customerId);
        if (confirmData.status === 1) {
          setConfirmPage(true);
          window.history.replaceState({}, document.title, '/');
        } else {
          failPopup(confirmData.error.errorMessage);
        }
      }
    }
  }

  async function onFail(error) {
    failPopup(error);
  }

  function failPopup(error) {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: error,
      confirmButtonColor: domainConfiguration.webConfigs.theme.primaryColor,
    });
  }

  const confirmCardOrder = async (orderId, txnId, customerId) => {
    setCabBooked(true);
    try {
      const bookingResponse = JSON.parse(localStorage.getItem('bookingResponse'));
      const url = `${process.env.REACT_APP_API_HOST_URL}/order/${orderId}/confirm`;
      let requestConfig = {
        method: 'PATCH',
        url,
        headers: {
          authorization: details.userToken,
          tenantid: details.tenantId,
          userId: details.userId,
          'response-version': `v2`,
          'x-sworks-timezone': getTimeZone(),
        },
      };
      const data = {
        startTime: bookingResponse.startTime,
        endTime: bookingResponse.endTime,
        stripeCustomerId: customerId,
        payment: {
          method: 'CARD',
          payeeCardId: txnId,
          payeeUserId: details.userId,
        },
      };
      requestConfig = JSON.parse(JSON.stringify(requestConfig));
      const result = await axios.patch(url, data, requestConfig);
      setCabBooked(false);
      localStorage.removeItem('bookingResponse');
      return result.data;
    } catch (error) {
      setCabBooked(false);
      localStorage.removeItem('bookingResponse');
      throw error;
    }
  };

  return (
    <>
      {cabBooked ? <Loader /> : <></>}
      {onLoadData ? (
        <>
          {!iframeValidation() && (
            <Header
              name={domainConfiguration.name}
              logo={domainConfiguration.logo}
              navConfigurations={domainConfiguration.webConfigs.navigation}
              fontStyle={domainConfiguration?.webConfigs?.theme?.styleNavbar}
            />
          )}
          {confirmPage ? (
            <BookingConfirm
              data={domainConfiguration?.webConfigs?.feature?.booking?.onConfirm}
              urls={domainConfiguration.url}
            ></BookingConfirm>
          ) : (
            <div className={`${styles.paymentBox}`}>
              <Payment stripeDetails={details} onSucess={onSuccess} onFail={onFail}></Payment>
            </div>
          )}
          {!iframeValidation() && (
            <Footer urls={domainConfiguration.url} name={domainConfiguration.name} domainData={domainConfiguration} />
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
};
export default StripePayment;
