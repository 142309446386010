export const themeOptions = [
  {
    '--border-primary': '#0000000D',
    '--primary': '#000000',
    '--secondary': '#161616',
    '--tertiary': '#161616',
    '--text-primary': '#FFFFFF',
    '--text-secondary': '#D2D2D2',
    '--icon-color': '#FFFFFF',
    name: 'Dark Curse',
  },
  {
    '--border-primary': '#E8F1FF',
    '--primary': '#FFFFFF',
    '--secondary': '#404040',
    '--tertiary': '#F4F4F6',
    '--text-primary': '#000000',
    '--text-secondary': '#000000CC',
    '--icon-color': '#C5C7CA',
    name: 'Bright Flowers',
  },
];
