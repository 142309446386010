import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../../components/Header/Header.lazy.js';

// Constants file
import Constants from '../../shared/constants/AppConstants';
import UrlConstants from '../../shared/constants/UrlConstants';
import { themeStyling } from '../../shared/providers/ThemeStyler';

// local components ...!
import Map from '../../components/GoogleMap/Map.lazy';
import Rider from '../../components/Rider/Rider.lazy';

// Support modules
import styles from './TrackingPage.module.scss';
import { useGAPageViewTracker } from '@sworksio/dashboard-core';
import { useParams } from 'react-router-dom';
import { sockethandler } from './socket/sockethandler';
import BotDetector from 'device-detector-js';
import Loader from '../../components//Loader/Loader';
import { getDomain, getTimeZone } from '../../shared/providers/Utils';
import decodePolyline from 'decode-google-map-polyline';

const TrackingPage = () => {
  const [errorCode, setErrorCode] = useState(null);
  const [domainConfiguration, setDomainConfiguration] = useState(null);
  const [websiteConfiguration, setWebsiteConfiguration] = useState(null);
  const [ride, setRide] = useState();
  const [theme, setTheme] = useState(Constants.SYSTEM.DEFAULT_THEME);
  const [driverlocation, setdriverlocation] = useState({});
  const [previousdriverlocation, setpreviousdriverlocation] = useState({});
  const [loader, setloader] = useState(true);
  const [showlabel, setLabel] = useState(false);

  const API_HOST_URL = process.env.REACT_APP_API_HOST_URL;
  const params = useParams();
  const pageView = useGAPageViewTracker();

  const botDetector = new BotDetector();
  let payload = {};

  useEffect(() => {
    handlegetdomain();
  }, []);

  // get a device info
  const getdeviceinfo = (result) => {
    const bot = botDetector.parse(window.navigator.userAgent);
    payload = {
      deviceType: bot.device.brand,
      deviceOs: bot.os.version,
      deviceModel: bot.device.brand,
      deviceManufacturer: bot.device.brand,
      timestamp: Date.now(), // Time stamp,
      token: result.token,
    };
    return payload;
  };

  const handlegetdomain = () => {
    const domain = new URL(window.location);
    const hostName = getDomain(domain.hostname);

    axios
      .get(`${API_HOST_URL}${UrlConstants.GET_DOMAIN_CONFIG}?domainName=${hostName}`)
      .then((res) => {
        processWebsiteConfigurationsData(res);
        handlegetrider(res?.data?.result);
      })
      .catch((err) => {
        processWebsiteConfigurationsError();
      });
  };

  // prepare a header after get a rider details ...!
  const configdataforsocket = (data, result) => {
    const payload = getdeviceinfo(result);
    payload['data'] = {
      userType: 'rider',
      trackingIds: [data?.result?._id],
      userId: data?.result?.userId,
      isRideStarted: true,
      trackingId: data?.result?._id,
    };
    sockethandler(payload, handlesocketcallback);
  };

  const handlegetrider = (result) => {
    axios
      .get(`${API_HOST_URL}/order/${params.id}`, {
        headers: {
          contentType: 'application/json',
          authorization: result?.token,
          tenantid: result?.tenantId,
          userid: result?.userId,
          'x-sworks-timezone': getTimeZone(),
        },
      })
      .then(({ data }) => {
        configdataforsocket(data, result);
        setRide(data.result);
        setloader(false);
        const actualRoute = data?.result?.routes?.actualRoute || null;
        if (actualRoute !== null) {
          const overviewPolyline = decodePolyline(actualRoute?.overviewPolyline);
          if (overviewPolyline.length < 5) {
            setLabel(true);
          }
        } else {
          setLabel(true);
        }
      })
      .catch((err) => {
        setErrorCode(err?.response?.status);
      });
  };

  const handlesocketcallback = (socket, callbackfor) => {
    // connection
    if (callbackfor === 'connect') {
      emitTostart(socket);
    }

    // t:location
    if (callbackfor === 't:location') {
      handleGetLocation(socket);
    }
  };

  // before start
  const emitTostart = (socket) => {
    // start a event
    payload['event'] = 't:start';
    socket.emit('t:start', payload);

    // join a event
    payload['event'] = 't:join';
    socket.emit('t:join', payload);
  };

  // Start and join a socket
  const handleGetLocation = (socket) => {
    setTimeout(() => {
      setpreviousdriverlocation(socket.data);
    }, [500]);
    setdriverlocation(socket);
  };

  function processWebsiteConfigurationsData(res) {
    if (res.data?.result) {
      const domainConfigurations = res.data?.result;
      if (!domainConfiguration || domainConfiguration === {}) {
        setErrorCode(404);
      }
      const websiteConfiguration = domainConfigurations.webConfigs;
      setDomainConfiguration(domainConfigurations);
      setWebsiteConfiguration(websiteConfiguration);
      // setToken(domainConfigurations.token);
      pageView(window.location.pathname + window.location.search);
      if (!websiteConfiguration?.theme) {
        setErrorCode(400);
      }
      themeStyling(websiteConfiguration?.theme?.primaryColor, websiteConfiguration?.theme?.name);
      if (!theme) {
        setTheme(websiteConfiguration?.theme);
      }
      localStorage.setItem(Constants.FIELD.CONFIGURATIONS, JSON.stringify(domainConfiguration));
    } else {
      processWebsiteConfigurationsError();
    }
  }

  function processWebsiteConfigurationsError() {
    setErrorCode(400);
  }

  let renderRiderWithMap = (
    <div className={styles.body}>
      {window.screen.width <= 700 ? (
        <>
          <Map
            ride={ride}
            driverlocation={driverlocation}
            previousdriverlocation={previousdriverlocation}
            domainConfiguration={domainConfiguration}
          />
          <Rider ride={ride} />
        </>
      ) : (
        <>
          <Rider ride={ride} />
          <Map
            ride={ride}
            driverlocation={driverlocation}
            previousdriverlocation={previousdriverlocation}
            domainConfiguration={domainConfiguration}
          />
        </>
      )}
    </div>
  );

  return (
    <div>
      <Header
        name={domainConfiguration?.name}
        logo={domainConfiguration?.logo}
        navConfigurations={websiteConfiguration?.navigation}
        domain={domainConfiguration}
        fontStyle={websiteConfiguration?.theme?.styleNavbar}
      />

      {/* Show loader */}
      {loader && <Loader />}

      {/* Show rides */}
      {ride && !loader && (
        <div className={styles.main}>
          {showlabel && ride?.status === 'COMPLETED' && <label>Not enough data to show complete route</label>}
          <p>Track Ride</p>
          {renderRiderWithMap}
        </div>
      )}
    </div>
  );
};

export default TrackingPage;
