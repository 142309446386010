import io from 'socket.io-client';

// create socket connection ...!
const createsocket = (headers) => {
  const API_HOST_URL = process.env.REACT_APP_TRACKING;
  const socket = io.connect(`${API_HOST_URL}/tracking`, {
    transports: ['websocket'],
    query: headers,
  });
  return socket;
};

// handle different method's
export const sockethandler = (headers, handlesocketcallback) => {
  // socket connection ..!
  const socket = createsocket(headers);

  // check socket connection
  socket.on('connect', () => {
    return handlesocketcallback(socket, 'connect');
  });

  // socket disconnection
  socket.on('disconnect', (data) => {
    return handlesocketcallback(data, 'disconnect');
  });

  // error
  socket.on('error', (data) => {
    return handlesocketcallback(data, 'error');
  });

  // connection time out
  socket.on('connect_timeout', (data) => {
    return handlesocketcallback(data, 'connect_timeout');
  });

  // connection error
  socket.on('connect_error', (data) => {
    return handlesocketcallback(data, 'connect_error');
  });

  // reconnection
  socket.on('reconnect', (data) => {
    return handlesocketcallback(data, 'reconnect');
  });

  // reconnection error
  socket.on('reconnect_error', (data) => {
    return handlesocketcallback(data, 'reconnect_error');
  });

  // reconnection failed
  socket.on('reconnect_failed', (data) => {
    return handlesocketcallback(data, 'reconnect_failed');
  });

  // get location
  socket.on('t:location', (data) => {
    return handlesocketcallback(data, 't:location');
  });
};
