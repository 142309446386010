// NPM modules
import React from 'react';
import { ProgressBar } from '@sworksio/dashboard-core';
// Constants file
import { getWebsiteConfiguration } from '../../shared/providers/Utils';
import Constants from '../../shared/constants/AppConstants';

const Loader = ({ props }) => {
  const configurations = getWebsiteConfiguration();
  const loaderConfiguration = configurations?.loader;
  const type = loaderConfiguration?.type || Constants.SYSTEM.DEFAULT_LOADER.TYPE;
  const color = loaderConfiguration?.color || Constants.SYSTEM.DEFAULT_LOADER.COLOR;
  const background = loaderConfiguration?.background || Constants.SYSTEM.DEFAULT_LOADER.BACKGROUND;
  return <ProgressBar type={type} color={color} background={background} />;
};

Loader.propTypes = {};

Loader.defaultProps = {};

export default Loader;
