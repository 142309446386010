// NPM modules
import React, { useState, useEffect } from 'react';
import { DynamicForm, Icon } from '@sworksio/dashboard-core';
import { size } from 'lodash';
import axios from 'axios';
import Swal from 'sweetalert2';
import Dot from 'dot-object';
// Constants file
import {
  OnLoadConfigurations,
  RideBookingConfigurations,
  TripDetailsConfigurations,
  UserConfigurations,
  OnConfirmBookingConfigurations,
  PaymentConfiguration,
} from '../../shared/constants/ConfigConstants';
import Loader from '../Loader/Loader';
import { getThemeConfigurations } from '../../shared/providers/Utils';
import styles from './RideEstimate.module.scss';
import './style.scss';
import BookingConfirm from '../BookingConfirm/BookingConfirm';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { getTimeZone } from '../../shared/providers/Utils';

const RideEstimate = ({ data, customTheme, tenantId, urls, domainConfiguration }) => {
  const [customWebsite, setCustomWebsite] = useState(false);
  const [cabDetails, setCabDetails] = useState({});
  const [showTripForm, setShowTripForm] = useState(true);
  const [showUserForm, setShowUserForm] = useState(false);
  const [cabBooked, setCabBooked] = useState(false);
  const [bookingConfirmed, setBookingConfirmed] = useState(false);
  const [catalogue, setCatalogue] = useState([]);
  const [bookingConfirmedConfigurations, setBookingConfirmedConfigurations] = useState(OnConfirmBookingConfigurations);
  const [rideDetailsConfigurations, setRideDetailsConfigurations] = useState(RideBookingConfigurations);
  const [tripDetailsConfigurations, setTripDetailsConfigurations] = useState(TripDetailsConfigurations);
  const [userDetailsConfigurations, setUserDetailsConfigurations] = useState(UserConfigurations);
  const themeColors = getThemeConfigurations();
  const dotHyphen = new Dot('-');
  const dot = new Dot('.');
  const [showPayForm, setPayForm] = useState(false);
  const [paymentOptions, setPaymentOptions] = useState(PaymentConfiguration);
  const [paymentType, setPaymentType] = useState('CASH');
  const [bookingResponse, setBookingResponse] = useState({});
  const [dotifyResponse, setDotifyResponse] = useState({});
  // const [selectedPackage, setSelectedPackage] = useState(null);
  const [packageData, setPackageData] = useState({});
  const [catlogData, setCatlogData] = useState({});
  // const [endTime, setEndTime] = useState(null);
  const [tripDetails, setTripDetails] = useState(null);

  const navigate = useNavigate();
  let defaultValue = {};

  useEffect(() => {
    getOnLoadDefaultValues(data, OnLoadConfigurations);
  }, []);

  useEffect(() => {}, [tripDetails]);

  function getOnLoadDefaultValues(configurations, onLoadConfig) {
    if (configurations.customWebsite) {
      setCustomWebsite(configurations.customWebsite);
    }

    if (configurations?.rideDetails) {
      setRideDetailsConfigurations({ ...rideDetailsConfigurations, ...configurations.rideDetails });
    }

    if (configurations?.userDetails) {
      setUserDetailsConfigurations({ ...userDetailsConfigurations, ...configurations.userDetails });
    }

    if (configurations?.tripDetails) {
      setTripDetailsConfigurations({ ...tripDetailsConfigurations, ...configurations.tripDetails });
    }

    if (configurations?.onConfirm) {
      setBookingConfirmedConfigurations({ ...bookingConfirmedConfigurations, ...configurations.onConfirm });
    }

    if (configurations?.paymentOptions) {
      setPaymentOptions(configurations.paymentOptions);
    }

    if (onLoadConfig?.selectableValue) {
      const loadConfigs = configurations?.onLoadConfigurations?.selectableValue
        ? configurations.onLoadConfigurations.selectableValue
        : onLoadConfig.selectableValue;
      for (const item of loadConfigs) {
        if (item.type === 'default' && size(defaultValue) === 0) {
          callGetCatalogueServiceWithoutSkip(item, item);
        }
      }
    }
  }

  function callGetCatalogueServiceWithoutSkip(webConfig, selectableConfig) {
    const API_HOST_URL = process.env.REACT_APP_API_HOST_URL;
    const limit = webConfig.limit || 20;
    let defaultTenantQuery = `?limit=${limit}`;
    if (size(webConfig?.defaultQueryParams) > 0) {
      defaultTenantQuery += `&${webConfig.defaultQueryParams}`;
    }
    const url = `${API_HOST_URL}/catalogue${defaultTenantQuery}`;
    let requestConfig = {
      method: 'GET',
      url,
      headers: {
        'content-type': 'application/json',
        tenantId: tenantId,
        'x-sworks-timezone': getTimeZone(),
      },
    };
    requestConfig = JSON.parse(JSON.stringify(requestConfig));
    axios
      .get(url, requestConfig)
      .then((res) => {
        defaultValue = {
          ...defaultValue,
          [selectableConfig.key]: {
            data: res.data?.result?.categories || [],
            config: selectableConfig,
          },
        };

        setCatalogue(res.data?.result?.categories || []);

        if (selectableConfig.key === 'parentId') {
          setPackageData(res.data?.result?.categories);
        }

        if (selectableConfig.key === 'catalogueId') {
          setCatlogData(res.data?.result?.categories);
        }

        processCatalogues(defaultValue);
      })
      .catch((err) => {
        return [];
      });
  }

  function processCatalogues(defaultValue) {
    const rideForm = data?.rideDetails ? data.rideDetails : rideDetailsConfigurations;
    for (let item of rideForm.form) {
      if (item.type === 'select' && item.options?.length === 0) {
        if (defaultValue[item.id]) {
          item.options =
            defaultValue[item.id].data.map((item) => {
              return {
                id: item._id,
                name: item.description ? `${item.title} (${item.description})` : item.title,
              };
            }) || [];
        }
      }
    }
    setRideDetailsConfigurations(rideForm);
    setShowTripForm(true);
  }

  async function onNextClick(data) {
    // console.log(data);
    const directionsService = new window.google.maps.DirectionsService();
    const origin = { lat: data.pickupAddress.latitude, lng: data.pickupAddress.longitude };
    const destination = { lat: data.dropAddress.latitude, lng: data.dropAddress.longitude };
    await directionsService.route(
      {
        origin: origin,
        destination: destination,
        travelMode: window.google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        // console.log();
        if (status === window.google.maps.DirectionsStatus.OK) {
          // console.log(result);
          const routes = getRouteObject(result.routes[0]);
          // console.log(routes);
          processEstimatePriceData(routes, data);
        } else {
          console.error(`error fetching directions ${JSON.stringify(result)}`);
          // const
          Swal.fire({
            title: 'Something went wrong please try again',
            // html: 'Error on fetching directions,',
            icon: 'error',
            showCancelButton: true,
            confirmButtonColor: domainConfiguration.webConfigs.theme.primaryColor,
            cancelButtonColor: themeColors.backgroundSecondary,
            confirmButtonText: 'ok',
          }).then((result) => {});

          //
        }
      },
    );
  }
  const processEstimatePriceData = (route, rideDetails) => {
    const catalogue = filterCatalog(rideDetails.catalogueId);
    const requestData = {
      order: {
        endTime: '',
        routes: { defaultRoute: route },
        address: {
          wayPoints: [],
          pickupAddress: rideDetails.pickupAddress,
          dropAddress: rideDetails.dropAddress,
        },
        quantity: 1,
        startTime: rideDetails.startTime,
      },
      catalogues: [catalogue],
    };
    getEstimatePrice(requestData);
  };

  const getEstimatePrice = (rideData) => {
    const API_HOST_URL = process.env.REACT_APP_API_HOST_URL;
    const url = `${API_HOST_URL}/order/catalogue/quotation`;
    let requestConfig = {
      method: 'POST',
      url,
      headers: {
        'content-type': 'application/json',
        authorization: domainConfiguration.token,
        tenantid: domainConfiguration.tenantId,
        userId: domainConfiguration.userId,
        userType: 'rider',
        appType: 'branded_taxi',
        'response-version': `v2`,
        'x-sworks-timezone': getTimeZone(),
      },
      data: { ...rideData },
    };
    requestConfig = JSON.parse(JSON.stringify(requestConfig));

    axios
      .post(url, requestConfig.data, requestConfig)
      .then((res) => {
        // console.log('catalogue/quotation..............', res);
        if (res.data.status === 1) {
          setShowTripForm(false);
          setPayForm(true);
          const data = { ...res.data.result.categories[0], ...rideData.order };
          setTripDetails(data);
          localStorage.setItem('tripDetails', JSON.stringify(data));
        }
      })
      .catch((err) => {
        return [];
      });
  };

  const filterCatalog = (id) => {
    return catalogue.find((item) => item._id === id);
  };

  const getRouteObject = (route) => {
    const overviewPolyline = route.overview_polyline;
    const viaAddress = route.summary;
    // TODO useful for tracking/navigation information
    // const steps = route.legs[0].steps;
    let distanceInMeter = 0;
    let totalMinutes = 0;
    for (const leg of route.legs) {
      distanceInMeter += leg.distance.value;
      totalMinutes += leg.duration.value;
    }
    let distance = '';
    if (route.legs[0].distance.text.includes('km')) {
      distance = `${(distanceInMeter / 1000).toFixed(2)} km`;
    } else {
      distance = `${(distanceInMeter / 1609.34).toFixed(2)} mi`;
    }
    const hours = Math.floor(totalMinutes / 3600);
    const minutes = Math.ceil(totalMinutes % 60);
    // TODO: Remove 0 hour kind of labels
    const duration = {
      value: totalMinutes,
      text: `${hours > 0 ? hours + 'hour' : ''} ${minutes} mins`,
    };
    const wayPoints = [];
    return { overviewPolyline, viaAddress, distance, duration, distanceInMeter, wayPoints };
  };

  async function onBookClick(data) {
    const gustBookingData = {
      address: tripDetails.address,
      catalogueId: tripDetails._id,
      dropAddress: tripDetails.address.dropAddress,
      pickupAddress: tripDetails.address.pickupAddress,
      quantity: '1',
      startTime: tripDetails.startTime,
      userData: {
        contactNumber: data['userData-contactNumber'],

        email: data['userData-email'],

        familyName: data['userData-familyName'],

        givenName: data['userData-givenName'],

        countryCode: '+1',
      },
    };
    bookingConfirmation(gustBookingData, rideDetailsConfigurations.staticData);
  }

  function callRideBookOrderService(data, staticData) {
    staticData = staticData || {};
    const quantity = data?.quantity || '1';
    const API_HOST_URL = process.env.REACT_APP_API_HOST_URL;
    data = dotHyphen.object(data);
    data.address = {
      dropAddress: data.dropAddress,
      pickupAddress: data.pickupAddress,
    };
    data.quantity = quantity;
    setCabBooked(true);

    const url = `${API_HOST_URL}/order/guest-booking`;
    let requestConfig = {
      method: 'POST',
      url,
      headers: {
        'content-type': 'application/json',
        authorization: domainConfiguration.token,
        tenantid: domainConfiguration.tenantId,
        userId: domainConfiguration.userId,
        userType: 'rider',
        appType: 'branded_taxi',
        'response-version': `v2`,
        'x-sworks-timezone': getTimeZone(),
      },
      data: { ...staticData, ...data },
    };
    requestConfig = JSON.parse(JSON.stringify(requestConfig));
    axios
      .post(url, requestConfig.data, requestConfig)
      .then((res) => {
        setCabBooked(false);
        if (res.data.status === 1) {
          processDataOnConfirm(res.data.result);
        } else {
          failPopup(res.data.error.errorMessage);
        }
      })
      .catch((err) => {
        setCabBooked(false);
        processErrorDataOnConfirm(err);
      });
  }

  function processDataOnConfirm(response) {
    // setShowUserForm(false);
    // setPayForm(true);
    setBookingResponse(response);
    localStorage.setItem('orderId', response?._id);
    localStorage.setItem('bookingResponse', JSON.stringify(response));
    const data = dot.dot(response);
    setDotifyResponse({ ...data, ...cabDetails });
    if (paymentOptions?.methods.length > 1) {
      if (paymentType === 'CARD') {
        paymentOrder();
      } else {
        confirmCashOrder();
      }
    } else {
      if (paymentOptions?.methods[0].type === 'CARD') {
        paymentOrder();
      } else {
        confirmCashOrder();
      }
    }
  }

  function onPayClick() {
    setPayForm(false);
    setShowUserForm(true);
  }

  const bookingConfirmation = (gustBookingData, staticData) => {
    // console.log(gustBookingData);
    const swalDescription = () => {
      switch (domainConfiguration.tenantId) {
        case 'slingshot001':
          return `You are about to book a slingshot (${bookingResponse?.title || ''})`;
        case 'auburn001':
          return `You are about to book a ride of ${bookingResponse?.title || ''}`;
        default:
          return `You are about to book a ride from <b>${gustBookingData?.pickupAddress?.addressLine1}</b> to <b>${gustBookingData?.dropAddress?.addressLine1}</b>`;
      }
      // window.location.href = '#tripDetails';
      // window.history.replaceState({}, document.title, '/');
    };

    Swal.fire({
      title: 'Are you sure?',
      html: swalDescription(),
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: domainConfiguration.webConfigs.theme.primaryColor,
      cancelButtonColor: themeColors.backgroundSecondary,
      confirmButtonText: 'Yes, book it!',
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem('tripDetails');
        callRideBookOrderService({ ...gustBookingData }, staticData);
      }
    });
  };

  function paymentOrder() {
    const domain = JSON.stringify(domainConfiguration);
    localStorage.setItem('domainConfiguration', domain);
    navigate('/payment', { state: { order: bookingResponse, domain } });
  }

  const confirmCashOrder = async () => {
    const orderId = localStorage.getItem('orderId');
    if (orderId) setCabBooked(true);
    try {
      const url = `${process.env.REACT_APP_API_HOST_URL}/order/${orderId}/confirm`;
      const requestBody = {
        startTime: bookingResponse.startTime,
        endTime: bookingResponse.endTime,
        payment: {
          method: 'CASH',
        },
      };
      let requestConfig = {
        method: 'PATCH',
        url,
        headers: {
          authorization: domainConfiguration.token,
          tenantid: domainConfiguration.tenantId,
          userId: domainConfiguration.userId,
          'response-version': `v2`,
        },
      };
      requestConfig = JSON.parse(JSON.stringify(requestConfig));
      const result = await axios.patch(url, requestBody, requestConfig);
      setCabBooked(false);
      if (result.data.status === 1) {
        onconfirmCashOrder(result);
      } else {
        failPopup(result.data.error.errorMessage);
      }
    } catch (error) {
      throw error;
    }
  };

  function failPopup(error) {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: error,
      confirmButtonColor: domainConfiguration.webConfigs.theme.primaryColor,
    });
  }

  function onconfirmCashOrder(res) {
    if (res.data?.result) {
      setCabBooked(false);
      setCabDetails({});
      setShowTripForm(false);
      setShowUserForm(false);
      if (bookingConfirmedConfigurations.title === '') {
        setShowTripForm(true);
        Swal.fire({
          title: 'Ride created',
          icon: 'success',
          confirmButtonColor: `${themeColors.backgroundPrimary}`,
        });
      } else {
        setPayForm(false);
        setBookingConfirmed(true);
      }
    } else {
      processErrorDataOnConfirm(res);
    }
  }

  function processErrorDataOnConfirm(err) {
    setCabBooked(false);
    setCabDetails({});
    setShowTripForm(true);
    setShowUserForm(false);
    setPayForm(false);
    const errorMessage = `There seems to be some issue with confirming the cab! Please try again after some time!`;
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: err.data?.error && err.data?.error.errorCode !== 6000 ? err.data.error?.errorMessage : errorMessage,
      confirmButtonColor: themeColors.backgroundPrimary,
    });
  }

  const handleChange = (e) => {
    setPaymentType(e.target.value);
  };

  const RenderTripDetails = ({ item, ...props }) => {
    // console.log(item);
    return (
      <div>
        <div className={`w-100 ${styles.ridetripdetails}`}>
          <div className="d-flex justify-content-between">
            <div>
              <p className={`mb-0 ${styles.text}`}>{`Pickup Address: ${item.address.pickupAddress.addressLine1}`}</p>
            </div>
          </div>
        </div>
        <div className={`w-100 ${styles.ridetripdetails}`}>
          <div className="d-flex justify-content-between margin-bottom:'2px'">
            <div>
              <p className={`mb-0 ${styles.text}`}>{`Dropoff Address: ${item.address.dropAddress.addressLine1}`}</p>
            </div>
          </div>
        </div>
        <div className={`w-100 ${styles.ridetripdetails}`}>
          <div className="d-flex justify-content-between">
            <div>
              <p className={`mb-0 ${styles.text}`}>{`Pickup Date: ${moment(item.startTime).format(
                'hh:mm A, MMM DD, YYYY',
              )}`}</p>
            </div>
          </div>
        </div>
        <div className={`w-100 ${styles.ridetripdetails}`}>
          <div className="d-flex justify-content-between">
            <div>
              <p className={`mb-0 ${styles.text}`}>{`Vehicle: ${item.title}`}</p>
            </div>
          </div>
        </div>
        <div className={`w-100 ${styles.ridetripdetails}`}>
          <div className="d-flex justify-content-between">
            <div>
              <p className={`mb-0 ${styles.text}`}>{`Estimated Price: ${item.estimatedPriceLabel}`}</p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const backGroundImg = data?.rideDetails?.backgroundImg
    ? data.rideDetails.backgroundImg
    : 'https://social-cfd-test.sworks.io/sworks001/5f69803e5f51595e85bcc632/324af09c595c30dbb8ece41554e02861.jpeg';

  const defaultBookingForm = () => {
    return (
      <DynamicForm
        fields={rideDetailsConfigurations.form}
        title={'Get your Estimate...'}
        submitLabel={rideDetailsConfigurations.submit}
        colorTheme={{
          backgroundColor: 'transparent',
          formTitleColor: '#000000',
          fieldTitleColor: '#000000',
          buttonPrimaryColor: themeColors.backgroundPrimary || '#3B8396',
        }}
        disableCancelButton={true}
        spacer={4}
        actionItemStyle={{
          position: 'unset',
          justifyContent: 'center',
          width: '100%',
          marginRight: 'unset',
          marginLeft: 'unset',
          backgroundColor: '#FFFFFF',
        }}
        onSubmit={(data) => onNextClick(data)}
      />
    );
  };

  function renderBookingForms() {
    return defaultBookingForm();
  }

  return (
    <>
      {bookingConfirmed ? (
        <section className={styles.rideConfirm} id="booking">
          <BookingConfirm data={bookingConfirmedConfigurations} urls={urls}></BookingConfirm>
        </section>
      ) : (
        <section className={styles.rideBooking} style={{ background: `url(${backGroundImg}) no-repeat` }} id="booking">
          {cabBooked ? <Loader /> : <></>}
          <div className={styles.container}>
            <div className={styles.rideBookingRow}>
              <div className={styles.rideBookingContent}>
                <h2 className={styles.textCenter}>{'Get your Estimate...'}</h2>
                <p className={styles.textCenter}>{rideDetailsConfigurations.description}</p>
              </div>
              <div className="bookride_div" style={{ width: '100%', maxWidth: '624px', minHeight: '100vh' }}>
                <div className={styles.bookingForm}>
                  <ul className={styles.bookingTab}>
                    <li>
                      <span className={showTripForm ? styles.active : ''} id="tripDetails">
                        <Icon iconName={`FaCar`} label={`Trip Details`} fontColor={`themeColors?.textSecondary`} />
                      </span>
                    </li>
                    <li>
                      <span className={showPayForm ? styles.active : ''} id="bookingSummary">
                        <Icon
                          iconName={`FaRegCreditCard`}
                          label={`Get Estimate`}
                          fontColor={`themeColors?.textSecondary`}
                        />
                      </span>
                    </li>
                    <li>
                      <span className={showUserForm ? styles.active : ''} id="accountDetails">
                        <Icon iconName={`FaUser`} label={`Account Details`} fontColor={`themeColors?.textSecondary`} />
                      </span>
                    </li>
                  </ul>
                  {showTripForm ? <>{renderBookingForms()}</> : <></>}

                  {showPayForm ? (
                    <form>
                      <div className={styles.tripDetails}>
                        <h2 className={styles.textCenter}>{rideDetailsConfigurations?.title}</h2>
                        <div>
                          <h4>Trip Details</h4>
                        </div>
                        {tripDetails !== null && <RenderTripDetails item={tripDetails} />}
                        <div style={{ display: 'flex' }}>
                          {size(paymentOptions.methods) > 1 ? (
                            <div
                              className={styles.payViaButton}
                              style={{
                                color: `${themeColors?.backgroundSecondary}`,
                                backgroundColor: `${themeColors?.textPrimary}`,
                              }}
                            >
                              <span>
                                <select className="form-control form-control-lg" onChange={(e) => handleChange(e)}>
                                  <option defaultValue={`Pay via ${paymentType}`} disabled hidden></option>
                                  {paymentOptions.methods.map((opt, index) => {
                                    return (
                                      <option key={index} value={opt?.type}>
                                        {opt?.label}
                                      </option>
                                    );
                                  })}
                                </select>
                              </span>
                            </div>
                          ) : (
                            <></>
                          )}
                          <div className={styles.payButton} onClick={() => onPayClick()}>
                            Next
                            {/* {`Pay ${
                            paymentOptions?.methods.length === 1 ? `via ${paymentOptions?.methods[0]?.label}` : ''
                          }`} */}
                          </div>
                        </div>
                      </div>
                    </form>
                  ) : (
                    <></>
                  )}
                  {showUserForm ? (
                    <DynamicForm
                      fields={userDetailsConfigurations.form}
                      title={rideDetailsConfigurations.title}
                      submitLabel={userDetailsConfigurations.submit}
                      colorTheme={{
                        backgroundColor: 'transparent',
                        formTitleColor: '#000000',
                        fieldTitleColor: '#000000',
                        buttonPrimaryColor: themeColors?.backgroundPrimary || '#3B8396',
                      }}
                      disableCancelButton={true}
                      spacer={4}
                      actionItemStyle={{
                        position: 'unset',
                        justifyContent: 'center',
                        width: '100%',
                        paddingBottom: 'unset',
                        paddingTop: 'unset',
                        marginRight: 'unset',
                        marginLeft: 'unset',
                        backgroundColor: '#FFFFFF',
                      }}
                      onSubmit={(data) => onBookClick(data)}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

RideEstimate.propTypes = {};

RideEstimate.defaultProps = {};

export default RideEstimate;
