import styles from './BookingConfirm.module.scss';
import DownloadLinks from '../DownloadLinks/DownloadLinks';
import { Icon } from '@sworksio/dashboard-core';
const BookingConfirm = ({ data, urls, props }) => {
  return (
    <section className={styles.bookingSuccessSection}>
      <div className={styles.container}>
        <div className={styles.desktopRow}>
          <div className={styles.bookingSuccessText}>
            <h2 className={`mb-2 mb-md-3 ${styles.textCenter}`}>
              <Icon iconName={`FaCheckCircle`} label={data.title} fontColor={'#3DB24B'} />
            </h2>
            <p className={styles.textCenter} dangerouslySetInnerHTML={{ __html: data.description }}></p>
            <div className={styles.desktop}>
              <h3>Or discover our Mobile app</h3>
              <DownloadLinks urls={urls} />
            </div>
          </div>
          <div className={styles.bookingSuccessImage}>
            <img src={data.image} alt="" />
          </div>
          <div className={`${styles.bookingSuccessText} ${styles.mobileTextCenter} ${styles.mobile}`}>
            <h3>Or discover our Mobile app</h3>
            <DownloadLinks urls={urls} />
          </div>
        </div>
      </div>
    </section>
  );
};
export default BookingConfirm;
